.flex {
  display: flex;
}

.flexWithSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.gameTitle {
  font-size: 80px;
  font-weight: bold;
}

.container {
  width: 500px;
  margin: 30px auto;
}

@media only screen and (min-width: 750px) and (max-height: 700px) {
  .container {
    width: 700px;
    .gameContainer {
      display: flex;
    }
  }
}

@media only screen and (max-width: 520px) {
  .container {
    width: 400px;
  }
}

@media only screen and (max-width: 420px) {
  .container {
    width: 300px;
  }
}