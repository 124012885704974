.gameResult {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fade-in 1200ms ease 500ms;
  animation-fill-mode: both;

  p {
    font-size: 60px;
    font-weight: bold;
    line-height: 60px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .continueButton {
    margin-right: 5px;
  }
}

.gameResultWin {
  background: rgba(237, 194, 46, 0.5);
}

.gameResultLose {
  background: rgba(238, 228, 218, 0.75);
}

#boardContainer {
  position: relative;
  padding-top: 15px;
  padding-left: 15px;
  cursor: default;
  background: #bbada0;
  border-radius: 6px;
  width: 500px;
  height: 500px;
  box-sizing: border-box;
  touch-action: none;
}

.gridContainer {
  position: absolute;
  z-index: 2;

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .cell {
      width: 106px;
      height: 106px;
      margin-right: 15px;
      float: left;
      border-radius: 3px;
      background: rgba(238, 228, 218, 0.35);
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 520px) {
  #boardContainer {
    width: 400px;
    height: 400px;
    padding-top: 12px;
    padding-left: 12px;
  }

  .gridContainer {
    .row {
      margin-bottom: 12px;

      .cell {
        width: 85px;
        height: 85px;
        margin-right: 12px;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  #boardContainer {
    width: 300px;
    height: 300px;
    padding-top: 9px;
    padding-left: 9px;
  }

  .gridContainer {
    .row {
      margin-bottom: 9px;

      .cell {
        width: 64px;
        height: 64px;
        margin-right: 9px;
      }
    }
  }
}
