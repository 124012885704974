html,
body {
  margin: 0;
  padding: 0;
  background: #faf8ef;
  color: #776e65;
}

html,
body,
button {
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
}

a {
  color: #776e65;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

hr {
  border: none;
  border-bottom: 1px solid #d8d4d0;
  margin-top: 20px;
  margin-bottom: 30px;
}


.dark-theme {
  background-color: #252525;;
}

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
}



/* Track */
.dark-theme ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(63, 63, 63, 0); 
  border-radius: 10px;
}

/* Handle */
.dark-theme ::-webkit-scrollbar-thumb {
  background: rgb(114, 113, 113); 
  border-radius: 10px;
}