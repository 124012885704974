.scoresContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.addScore {
  position: absolute;
  right: 30px;
  font-size: 25px;
  line-height: 25px;
  font-weight: bold;
  color: rgba(119, 110, 101, 0.9);
  z-index: 100;
  animation: move-up 600ms ease-in;
  animation-fill-mode: both;
}

@keyframes move-up {
  
  0%  {
    top: 25px;
    opacity: 1;
  }
  100% {
    top: -50px;
    opacity: 0;
  }
}
